// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN INPUT */

.ant-input-disabled {
  background: $gray-lighten-more-more;
}

.ant-input-number {
  width: 100% !important;
}

// Make width of label Form.Item 100%
.ant-col .ant-form-item-label label {
  width: 100% !important;
}
