// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SELECT */

.ant-select {
  font-family: 'Mukta', sans-serif;
}

.ant-select-dropdown {
  border: 1px solid #e9e9e9;
  animation-duration: 0s !important;
}

.ant-select-auto-complete {
  ol,
  ul,
  dl {
    margin: 0;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  padding: 3px 8px;
}
