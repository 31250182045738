@import '../../assets/styles/mixins.scss';


.notImage {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-color: $gray-lighten-more;
}

.filterImage {
  filter: brightness(0.6);
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

@media (max-width: $xl-max-width) {
  .lazy-image {
    height: 1000px !important;
  }
}

@media (max-width: 1024px) {
  .lazy-image {
    height: 900px !important;
  }
}

@media (max-width: 998px) {
  .lazy-image {
    height: 750px !important;
  }
}

@media (max-width: 768px) {
  .lazy-image {
    height: 680px !important;
  }
}