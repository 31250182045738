// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN CARD */
.ant-card-actions {
  background-color: #fff;
  border-top: 1px solid $gray-border;
}

.ant-card-body {
  border-top: 1px solid $gray-border;
}

.ant-card-bordered {
  border: 1px solid $gray-border;
}
