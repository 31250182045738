@import 'assets/styles/mixins.scss';

.topbar_admin {
  background: $white;
  padding: 0 rem(20);
  min-height: 64px;
  height: 64px;
  border-bottom: 1px solid $gray-border;
  color: $text;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: center;
}

.topbar {
  background: $menuBg-darken;
  padding: 0 rem(20);
  min-height: 64px;
  height: 64px;
  border-bottom: 1px solid $menuBg-darken;
  color: $text;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: center;
}

.alertContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alertIcon {
  width: 20px;
  height: 20px;
}

.notificationWrapper {
  position: relative;

  cursor: pointer;
}

.notificationCount {
  position: absolute;
  top: -10px;
  right: -10px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  background-color: #41b883;
  border-radius: 50%;

  color: #fff;
}
