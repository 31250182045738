@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/Antd/antd.cleanui';
@import 'assets/styles/CleanUI/cleanui';
@import 'assets/styles/Bootstrap/bootstrap.cleanui';
@import 'assets/styles/Chartist/chartist.cleanui';
@import 'assets/styles/Nprogress/nprogress.cleanui';
@import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
@import 'assets/styles/Fonts/fonts.scss';
@import '~c3/c3.min.css';
@import 'assets/styles/PerfectScrollbar/perfectsScrollbar.cleanui.scss';

*::placeholder {
  opacity: 0.5;
}

.ant-tooltip-inner {
  text-align: center;
}
