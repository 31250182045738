.Layout__contentWrapper {
  padding: 2rem 5%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Layout__content {
  width: 100%;
  max-width: 104rem;
  display: flex;
  flex-direction: column;
}
