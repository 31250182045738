  .ant-modal-body{
    border-bottom-left-radius: 16px  ;
    border-bottom-right-radius: 16px  ;
  }
  .ant-modal-header{
    border-top-left-radius: 16px ;
    border-top-right-radius: 16px ;
  }
  .ant-modal-content{
    border-radius: 16px ;
  }

  .description{
    text-align: center;
    font-size: 16px;
  }
  .email{
    text-align: center;
  }