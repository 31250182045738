h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  // white-space: nowrap;
}
